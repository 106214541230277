.login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.main {
  width: 54%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top {
  margin-bottom: 32px;
  position: relative;
}
.top > img {
  display: none;
}
.message {
  position: absolute;
  bottom: -30px;
  font-size: 12px;
  width: 100%;
  padding: 10px 8px;
  border-radius: 8px;
  color: white;
  opacity: 0;
  visibility: hidden;
}
.message.error {
  background-color: var(--primary-red);
  transition: 0.3s;
}
.message.success {
  background-color: #00ee98;
  transition: 0.3s;
}
.message.active {
  opacity: 1;
  visibility: visible;
  bottom: -28px;
}
.main form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  margin-top: 32px;
}
.main form .top-text {
  margin-bottom: 32px;
  position: relative;
}
.main form .top-text h2 {
  color: var(--deep-purple);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 43px;
}
.main form .top-text p {
  color: var(--grey-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.password {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 22px 16px 6px 0;
  margin-bottom: 5px;
  background: var(--grey-100);
  border-radius: 8px;
  position: relative;
  border: 2px solid var(--grey-100);
}
.password input {
  background: var(--grey-100);
  border: none;
  outline: none;
  width: 100%;
  margin-left: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--deep-purple);
}
.password span {
  position: absolute;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-text);
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  pointer-events: none;
}
.password:focus-within {
  border: 2px solid var(--flood-blue);
}
.password.error {
  border: 2px solid var(--primary-red);
}

.password input:focus ~ span,
.password input:not(:placeholder-shown) ~ span {
  top: 16px;
  font-size: 11px;
}
span.error {
  opacity: 0;
  margin-left: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-red);
}
span.error.active {
  opacity: 1;
}
.login form:not(:last-child) .error {
  margin-bottom: 5px;
}
.password .eye {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
.forget {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.forget span {
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  letter-spacing: 0.01em;
  color: var(--flood-blue);
  cursor: pointer;
}
.submit {
  background-color: var(--flood-blue);
  color: white;
  border: none;
  padding: 12px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12);
  transition: 0.3s;
  cursor: pointer;
}
.submit:hover {
  background-color: #1992e5;
}
.submit.active {
  pointer-events: none;
  background-color: #1891e2b6;
}

@media (max-width: 1200px) {
  .login {
    overflow: visible;
    height: unset;
    min-height: 100vh;
  }
  .main {
    padding: 60px 0;
    justify-content: flex-start;
  }

  .main form {
    margin-top: 0;
  }
  .main form,
  .btns {
    max-width: 376px;
  }
}
@media (max-width: 765px) {
  .login {
    justify-content: center;
  }
  .image {
    display: none;
  }
  .main {
    width: 100%;
    padding: 40px 24px;
  }
  .top > img {
    display: block;
    margin-bottom: 54px;
  }

  .main form {
    max-width: 100%;
  }
  .main form .top-text h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .main form .top-text p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 440px) {
  .image {
    background-position-x: -120px;
  }
}
