@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --flood-blue: #1ca3ff;
  --deep-purple: #061143;
  --primary-red: #ff1d4e;
  --red-200: #f592a3;
  --warning: #f9c235;
  --success: #00be5f;
  --grey-text: #777b8f;
  --grey-stroke: #e5e6eb;
  --grey-placeholder: #9fa2af;
  --grey-100: #eff0f2;
}
a {
  text-decoration: none;
  color: unset;
}
