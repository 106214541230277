.button {
  width: 100%;
  background: var(--flood-blue);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.disabled {
  background: var(--grey-300);
  pointer-events: none;
}
.button.disabled:focus {
  background: var(--grey-300);
  pointer-events: none;
}

.button:hover {
  background-color: #1b94e5;
}

.button.submitting {
  background-color: #1891e2;
  pointer-events: none;
}

.button svg circle {
  stroke-opacity: 1;
}

@media (max-width: 600px) {
  .button {
    width: 100%;
  }
}
