.input {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 22px 16px 6px 0;
  margin-bottom: 5px;
  background: var(--grey-100);
  border-radius: 8px;
  position: relative;
  border: 2px solid var(--grey-100);
}

.input input {
  background: var(--grey-100);
  border: none;
  outline: none;
  width: 100%;
  margin-left: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--deep-purple);
}
.input > span {
  position: absolute;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-text);
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  pointer-events: none;
}
.input:focus-within {
  border: 2px solid var(--flood-blue);
}
.input.error {
  border: 2px solid var(--primary-red);
}
.input input:focus ~ span,
.input input:not(:placeholder-shown) ~ span {
  top: 16px;
  font-size: 11px;
}

span.error {
  opacity: 0;
  margin-left: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--primary-red);
}
span.error.active {
  opacity: 1;
}
