.reset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}
.close {
  position: absolute;
  top: 30px;
  right: 40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 4;
}
.main {
  width: 54%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 416px;
  margin-top: 32px;
}

.main .top {
  margin-bottom: 32px;
  position: relative;
}

.main .top img {
  display: none;
}

.main .top-text {
  margin-bottom: 32px;
  position: relative;
}
.main .top-text h2 {
  color: var(--deep-purple);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 43px;
}
.main .top-text p {
  color: var(--grey-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.message {
  position: absolute;
  bottom: -30px;
  font-size: 12px;
  width: 100%;
  padding: 10px 8px;
  border-radius: 8px;
  color: white;
  opacity: 0;
  visibility: hidden;
}
.message.error {
  background-color: var(--primary-red);
  transition: 0.3s;
}
.message.success {
  background-color: #00ee98;
  transition: 0.3s;
}
.message.active {
  opacity: 1;
  visibility: visible;
  bottom: -28px;
}

.main button {
  margin-top: 8px;
}

.main form > span:last-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--flood-blue);
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
}

.reset-email {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reset-email h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--deep-purple);
  margin: 30px 0 24px 0;
  text-align: center;
}
.reset-email p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.005em;
  color: var(--deep-purple);
  text-align: center;
  max-width: 310px;
}

.reset-email p:last-of-type {
  color: var(--primary-red);
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .reset {
    overflow: visible;
    height: unset;
    min-height: 100vh;
  }
  .main {
    padding: 60px 0;
    justify-content: flex-start;
  }

  .main.reset {
    justify-content: center;
  }

  .main form {
    max-width: 376px;
    margin-top: 0;
  }
}

@media (max-width: 765px) {
  .reset {
    justify-content: center;
  }
  .close {
    right: 20px;
  }
  .main {
    width: 100%;
    padding: 40px 24px;
  }

  .main form {
    max-width: 100%;
  }

  .main .top img {
    display: block;
    margin-bottom: 54px;
  }

  .main .top-text h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .main .top-text p {
    font-size: 14px;
    line-height: 20px;
  }
  .reset-email h2 {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 1px;
  }
  .reset-email p {
    font-size: 14px;
    line-height: 20px;
  }
}
