.image {
  background-image: url(../../../public/signup-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  width: 46%;
  padding: 60px 40px;
  position: relative;
}
.image::after {
  content: "";
  position: absolute;
  top: 60px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 40px solid #009bc4;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  transform: translateX(100%);
}
.image h2 {
  max-width: 100px;
  font-weight: 800;
  font-size: 100px;
  line-height: 77px;
  text-transform: uppercase;
  color: #ffffff;
}
.image h2 span {
  color: #061143;
}
@media (max-width: 1200px) {
  .image::after {
    display: none;
  }
  .image h2 {
    max-width: 500px;
  }
}
@media (max-width: 765px) {
  .image {
    display: none;
  }
}
