.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}
.loader img {
  position: absolute;
  right: 20px;
  bottom: 30px;
  width: 126px;
  height: 40px;
}
@media (max-width: 765px) {
  .loader img {
    width: 90px;
    height: 30px;
  }
}
